import {get } from "../utils/http"
import { baseUrls } from '../utils/urls'
const aps1 = baseUrls.aps1;
//日志
export function getLogsData(page, size) {
    return get(`${aps1}/operationLog/page?page=${page}&size=${size}`)
}

//日志--搜索
export function searchLogsData(page, size, searchValue,startTime,endTime) {
    return get(`${aps1}/operationLog/page?page=${page}&size=${size}&searchValue=${searchValue}&startTime=${startTime}&endTime=${endTime}`)
}

//日志--日期选择
export function searchDate(page, size, startTime, endTime) {
    return get(`${aps1}/operationLog/page?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}`)
}